<template>
  <v-chip
    :color="applyColor ? colors[status].background : null"
    :text-color="applyColor ? colors[status].text : null"
    v-bind="$attrs"
    v-on="$listeners"
    label
  >
    <span :style="{ width: width ? width + 'px' : 'auto' }">
      {{ capitalizeString(status.replaceAll("-", " ")) }}
    </span>

    <slot />
  </v-chip>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "StatusChip",

  // Accept incoming data from parent
  props: {
    status: {
      type: String,
      required: true
    },

    width: {
      type: Number,
      required: false,
      default: null
    },

    applyColor: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  // Define local key map store
  data: () => ({
    // Map for status chip colors
    colors: {
      ignored: {
        background: "blue-grey lighten-3",
        text: "black"
      },
      waiting: {
        background: "yellow lighten-2",
        text: "black"
      },
      pending: {
        background: "orange lighten-3",
        text: "black"
      },
      aggregating: {
        background: "orange lighten-2",
        text: "black"
      },
      "processing": {
        background: "blue darken-2",
        text: "white"
      },
      "in-progress": {
        background: "blue darken-2",
        text: "white"
      },
      error: {
        background: "red darken-1",
        text: "white"
      },
      failed: {
        background: "red darken-1",
        text: "white"
      },
      cancelled: {
        background: "red darken-1",
        text: "white"
      },
      completed: {
        background: "green darken-1",
        text: "white"
      },
      success: {
        background: "green darken-1",
        text: "white"
      }
    },
  })
}
</script>
